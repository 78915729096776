<template>
  <v-app>
    <v-dialog fullscreen
              v-model="dialog">
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            Preview character certificate
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">

            <v-col cols="9">

              <div class="outer-border bgimg" v-bind:style="{ 'width':settings.orientation=='portrait' ? '793.92px' : '1122.24px','height':settings.orientation=='portrait' ? '1122.24px' : '793.92px','background-image': 'url(' + previewImage + ')' }">
                <div style="position: relative; width: 100%" >
                  <div style="position: absolute; width: 100%">
                    <div v-if="character_certificate_fields.includes('student_name')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.student_name_position_top  + 'px', left: template.student_name_position_left + 'px' }" >
                      Student name
                    </div>
                    <div v-if="character_certificate_fields.includes('certificate_date')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.certificate_date_position_top  + 'px', left: template.certificate_date_position_left + 'px' }" >
                      Certificate date
                    </div>
                    <div v-if="character_certificate_fields.includes('program_name')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.program_name_position_top  + 'px', left: template.program_name_position_left + 'px' }" >
                      Program name
                    </div>
                    <div v-if="character_certificate_fields.includes('completion_date')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.completion_date_position_top  + 'px', left: template.completion_date_position_left + 'px' }" >
                      Completion date
                    </div>
                    <div v-if="character_certificate_fields.includes('parent_name')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.parent_name_position_top  + 'px', left: template.parent_name_position_left + 'px' }" >
                      Parent name
                    </div>
                    <div v-if="character_certificate_fields.includes('date_of_birth')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.date_of_birth_position_top  + 'px', left: template.date_of_birth_position_left + 'px' }" >
                      Date of birth
                    </div>
                    <div  v-if="character_certificate_fields.includes('academic_year')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.academic_year_position_top  + 'px', left: template.academic_year_position_left + 'px' }" >
                      Academic year
                    </div>
                    <div  v-if="character_certificate_fields.includes('address')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.address_position_top  + 'px', left: template.address_position_left + 'px' }" >
                      Address
                    </div>
                    <div  v-if="character_certificate_fields.includes('registration_no')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.registration_no_position_top  + 'px', left: template.registration_no_position_left + 'px' }" >
                      Registration no
                    </div>
                    <div  v-if="character_certificate_fields.includes('gpa')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.gpa_position_top  + 'px', left: template.gpa_position_left + 'px' }" >
                      GPA
                    </div>
                    <div  v-if="character_certificate_fields.includes('division')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.division_position_top  + 'px', left: template.division_position_left + 'px' }" >
                      Division
                    </div>
                    <div  v-if="character_certificate_fields.includes('transcript_issue_no')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.transcript_issue_no_position_top  + 'px', left: template.transcript_issue_no_position_left + 'px' }" >
                      Transcript issue no.
                    </div>
                    <div  v-if="character_certificate_fields.includes('passed_year')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.passed_year_position_top  + 'px', left: template.passed_year_position_left + 'px' }" >
                      Passed year
                    </div>
                    <div  v-if="character_certificate_fields.includes('grade')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.grade_position_top  + 'px', left: template.grade_position_left + 'px' }" >
                      Grade
                    </div>

                    <div  v-if="character_certificate_fields.includes('academic_year_start')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.academic_year_start_position_top  + 'px', left: template.academic_year_start_position_left + 'px' }" >
                      Academic year start
                    </div>
                    <div  v-if="character_certificate_fields.includes('academic_year_end')" class="fs-15" style="position: absolute" v-bind:style="{ top: template.academic_year_end_position_top  + 'px', left: template.academic_year_end_position_left + 'px' }" >
                      Academic year end
                    </div>

                  </div>
                </div>
                <span class="certification">
                {{template.file_name}}
              </span>
              </div>

            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-standard cancel-btn"
              depressed
              @click="closeDialog"
          >Cancel
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import TemplatesService from "@/core/services/templates/TemplatesService";
const template=new TemplatesService();
export default {
  data(){
    return{
      dialog:false,
      template:{

      },
      character_certificate_fields:[],
      settings: {
        orientation: 'portrait',
        template_for: 'Student',
      },
      previewImage:null,
      programDetail:null,

    }
  },
  methods:{
    openDialog(){
      this.dialog=true;

    },
    closeDialog(){
      this.dialog=false;
    },
    resetData(){
      this.character_certificate_fields=[];
      this.programDetail=null;
      this.previewImage=null;
    },
    previewTemplate(templateId){
      this.openDialog();
      this.getTemplateDetail(templateId);
    },
    getTemplateDetail(templateId){
      template
          .show(templateId)
          .then(response => {
            this.template=response.data.templates;
            if(this.template){
              this.programDetail=this.template.program;
              if(this.programDetail){
                this.changeProgram();
              }
              this.previewImage=this.template.image_path['real'];
              this.settings.orientation=this.template.settings.orientation;
            }

          })
          .catch(error => {

          })
          .finally(() => {
          });
    },
    changeProgram(){
      if(this.programDetail && this.programDetail.character_certificate_fields){
        this.character_certificate_fields=this.programDetail.character_certificate_fields.split(',');
      }else{
        this.character_certificate_fields=[];
      }
    },

  }
}
</script>
<style scoped>
.outer-border{
  padding:20px; text-align:center;     margin-left: 21%;
}



.certification{
  font-size:50px; font-weight:bold;  ;
}

.certify{
  font-size:25px;
}

.name{
  font-size:30px;    color: green;
}

.fs-30{
  font-size:30px;
  font-weight: bold;
}

.fs-20{
  font-size:20px;
  font-weight: bold;
}
.fs-15{
  font-size:15px;
  font-weight: bold;
}
.bgimg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
