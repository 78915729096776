<template>
  <v-app>
    <v-dialog fullscreen v-model="dialog">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{ `${edit ? "Update" : "Add"}` }} {{ titleType }}
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">
            <div class="col-12 col-md-4">
              <v-text-field
                v-model="template.file_name"
                outlined
                dense
                :error="$v.template.file_name.$error"
                label="Title"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.template.file_name.$error"
                >This information is required</span
              >
            </div>
            <div class="col-12 col-md-4">
              <v-file-input
                @change="Preview_image"
                v-model="template.bg_image"
                label="Background image"
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                @click:clear="previewImage = null"
                outlined
                :error="$v.template.bg_image.$error"
                dense
              ></v-file-input>
              <span class="text-danger" v-if="$v.template.bg_image.$error"
                >This information is required</span
              >
            </div>
            <div class="col-12 col-md-4">
              <v-select
                @change="changeProgram"
                :items="programs"
                v-model="programDetail"
                outlined
                item-text="title"
                return-object
                dense
                :error="$v.programDetail.$error"
                label="Program"
              >
              </v-select>
              <span class="text-danger" v-if="$v.programDetail.$error"
                >This information is required</span
              >
            </div>

            <div class="col-12 col-md-2">
              <v-text-field
                  v-model="template.top"
                  type="number"
                  label="Top"
                  color="primary"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger">**This should be is millimeter size</span>
            </div>

            <div class="col-12 col-md-2">
              <v-text-field
                  v-model="template.bottom"
                  type="number"
                  label="Bottom"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger">**This should be is millimeter size</span>
            </div>

            <div class="col-12 col-md-2">
              <v-text-field
                  v-model="template.left"
                  type="number"
                  label="Left"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger">**This field should be millimeter size</span>
            </div>

            <div class="col-12 col-md-2">
              <v-text-field
                  v-model="template.right"
                  type="number"
                  label="Right"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger">**This field should be millimeter size</span>
            </div>

            <div class="col-12 col-md-2">
              <v-text-field
                  v-model="template.gap_bottom"
                  type="number"
                  label="Gap bottom"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger">**This field should be millimeter size</span>
            </div>

            <div class="col-12 col-md-2">
              <v-text-field
                  v-model="template.gap_right"
                  type="number"
                  label="Gap right"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger">**This field should be millimeter size</span>
            </div>
          </div>
          <div class="row" v-if="template">
            <v-col cols="3">
              <div class="text-left mb-6">
                <h5 class="font-weight-medium">Option</h5>
              </div>

              <v-row>
                <div class="col-12">
                  <v-radio-group v-if="template" v-model="settings.orientation">
                    <template v-slot:label>
                      <div>Select <strong>orientation</strong></div>
                    </template>
                    <v-radio @click="changeSizeValue" value="portrait">
                      <template v-slot:label>
                        <div>
                          <strong class="success--text">Portrait</strong>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio @click="changeSizeValue" value="landscape">
                      <template v-slot:label>
                        <div>
                          <strong class="primary--text">Landscape</strong>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </v-row>
              <v-row v-if="template.type == 'character_certificate'">
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('student_name')"
                >
                  <label>
                    <strong>Student name position (Top) </strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.student_name_position_top"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('student_name')"
                >
                  <label>
                    <strong>Student name position (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.student_name_position_left"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('certificate_date')
                  "
                >
                  <label>
                    <strong>Certificate date position (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.certificate_date_position_top"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('certificate_date')
                  "
                >
                  <label>
                    <strong>Certificate date position (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.certificate_date_position_left"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('program_name')"
                >
                  <label>
                    <strong>Program name position (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.program_name_position_top"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('program_name')"
                >
                  <label>
                    <strong>Program name position (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.program_name_position_left"
                    ticks
                  ></v-slider>
                </v-col>

                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('completion_date')
                  "
                >
                  <label>
                    <strong>Completion date position (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.completion_date_position_top"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('completion_date')
                  "
                >
                  <label>
                    <strong>Completion date position (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.completion_date_position_left"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('parent_name')"
                >
                  <label>
                    <strong>Parent name position (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.parent_name_position_top"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('parent_name')"
                >
                  <label>
                    <strong>Parent name position (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.parent_name_position_left"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('date_of_birth')"
                >
                  <label>
                    <strong>Date of birth position (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.date_of_birth_position_top"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('date_of_birth')"
                >
                  <label>
                    <strong>Date of birth position (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.date_of_birth_position_left"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('academic_year')"
                >
                  <label>
                    <strong>Academic year position (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.academic_year_position_top"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('academic_year')"
                >
                  <label>
                    <strong>Academic year position (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.academic_year_position_left"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('address')"
                >
                  <label>
                    <strong>Address position (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.address_position_top"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('address')"
                >
                  <label>
                    <strong>Address position (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.address_position_left"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('registration_no')
                  "
                >
                  <label>
                    <strong>Registration no position (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.registration_no_position_top"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('registration_no')
                  "
                >
                  <label
                    ><strong>Registration no position (Left)</strong></label
                  >
                  <v-slider
                    :max="size.left_max"
                    v-model="template.registration_no_position_left"
                    ticks
                  ></v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('gpa')"
                >
                  <label>
                    <strong>Grade average point (Top) </strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.gpa_position_top"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('gpa')"
                >
                  <label>
                    <strong>Grade average point (Left) </strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.gpa_position_left"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('grade')"
                >
                  <label>
                    <strong>Grade (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.grade_position_top"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('grade')"
                >
                  <label>
                    <strong>Grade (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.grade_position_left"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('division')"
                >
                  <label>
                    <strong>Division (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.division_position_top"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('division')"
                >
                  <label>
                    <strong>Division (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.division_position_left"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('transcript_issue_no')
                  "
                >
                  <label>
                    <strong>Transcript issue no. (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.transcript_issue_no_position_top"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('transcript_issue_no')
                  "
                >
                  <label>
                    <strong>Transcript issue no. (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.transcript_issue_no_position_left"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('passed_year')"
                >
                  <label>
                    <strong>Passed year (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.passed_year_position_top"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="character_certificate_fields.includes('passed_year')"
                >
                  <label>
                    <strong>Passed year (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.passed_year_position_left"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('academic_year_start')
                  "
                >
                  <label>
                    <strong>Academic year start (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.academic_year_start_position_top"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('academic_year_start')
                  "
                >
                  <label>
                    <strong>Academic year start (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.academic_year_start_position_left"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('academic_year_end')
                  "
                >
                  <label>
                    <strong>Academic year end (Top)</strong>
                  </label>
                  <v-slider
                    :max="size.top_max"
                    v-model="template.academic_year_end_position_top"
                    ticks
                  >
                  </v-slider>
                </v-col>
                <v-col
                  cols="6"
                  v-if="
                    character_certificate_fields.includes('academic_year_end')
                  "
                >
                  <label>
                    <strong>Academic year end (Left)</strong>
                  </label>
                  <v-slider
                    :max="size.left_max"
                    v-model="template.academic_year_end_position_left"
                    ticks
                  >
                  </v-slider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="9">
              <div class="text-center mb-6 mr-16">
                <h5 class="font-weight-medium">Print Preview</h5>
              </div>
              <div
                class="outer-border bgimg"
                v-bind:style="{
                  'margin-left':
                    settings.orientation !== 'portrait' ? '-10%' : '0',
                  width:
                    settings.orientation === 'portrait'
                      ? '793.92px'
                      : '1122.24px',
                  height:
                    settings.orientation === 'portrait'
                      ? '1122.24px'
                      : '793.92px',
                  'background-image': 'url(' + previewImage + ')',
                }"
              >
                <div
                  style="position: relative; width: 100%"
                  v-if="template.type == 'character_certificate'"
                >
                  <div style="position: absolute; width: 100%">
                    <div
                      v-if="
                        character_certificate_fields.includes('student_name')
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.student_name_position_top + 'px',
                        left: template.student_name_position_left + 'px',
                      }"
                    >
                      Student name
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes(
                          'certificate_date'
                        )
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.certificate_date_position_top + 'px',
                        left: template.certificate_date_position_left + 'px',
                      }"
                    >
                      Certificate date
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes('program_name')
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.program_name_position_top + 'px',
                        left: template.program_name_position_left + 'px',
                      }"
                    >
                      Program name
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes('completion_date')
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.completion_date_position_top + 'px',
                        left: template.completion_date_position_left + 'px',
                      }"
                    >
                      Completion date
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes('parent_name')
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.parent_name_position_top + 'px',
                        left: template.parent_name_position_left + 'px',
                      }"
                    >
                      Parent name
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes('date_of_birth')
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.date_of_birth_position_top + 'px',
                        left: template.date_of_birth_position_left + 'px',
                      }"
                    >
                      Date of birth
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes('academic_year')
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.academic_year_position_top + 'px',
                        left: template.academic_year_position_left + 'px',
                      }"
                    >
                      Academic year
                    </div>
                    <div
                      v-if="character_certificate_fields.includes('address')"
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.address_position_top + 'px',
                        left: template.address_position_left + 'px',
                      }"
                    >
                      Address
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes('registration_no')
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.registration_no_position_top + 'px',
                        left: template.registration_no_position_left + 'px',
                      }"
                    >
                      Registration no
                    </div>
                    <div
                      v-if="character_certificate_fields.includes('gpa')"
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.gpa_position_top + 'px',
                        left: template.gpa_position_left + 'px',
                      }"
                    >
                      GPA
                    </div>
                    <div
                      v-if="character_certificate_fields.includes('division')"
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.division_position_top + 'px',
                        left: template.division_position_left + 'px',
                      }"
                    >
                      Division
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes(
                          'transcript_issue_no'
                        )
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.transcript_issue_no_position_top + 'px',
                        left: template.transcript_issue_no_position_left + 'px',
                      }"
                    >
                      Transcript issue no.
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes('passed_year')
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.passed_year_position_top + 'px',
                        left: template.passed_year_position_left + 'px',
                      }"
                    >
                      Passed year
                    </div>
                    <div
                      v-if="character_certificate_fields.includes('grade')"
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.grade_position_top + 'px',
                        left: template.grade_position_left + 'px',
                      }"
                    >
                      Grade
                    </div>

                    <div
                      v-if="
                        character_certificate_fields.includes(
                          'academic_year_start'
                        )
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.academic_year_start_position_top + 'px',
                        left: template.academic_year_start_position_left + 'px',
                      }"
                    >
                      Academic year start
                    </div>
                    <div
                      v-if="
                        character_certificate_fields.includes(
                          'academic_year_end'
                        )
                      "
                      class="fs-15"
                      style="position: absolute"
                      v-bind:style="{
                        top: template.academic_year_end_position_top + 'px',
                        left: template.academic_year_end_position_left + 'px',
                      }"
                    >
                      Academic year end
                    </div>
                  </div>
                </div>
                <span class="certification">
                  {{ template.file_name }}
                </span>
              </div>
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn btn-standard cancel-btn"
            depressed
            @click="closeDialog"
            >Cancel
          </v-btn>
          <v-btn
            class="text-white ml-2 btn btn-primary"
            depressed
            :loading="loading"
            dark
            @click="saveCertificate()"
            v-if="
              checkIsAccessible('character-certificate', 'create') ||
              checkIsAccessible('character-certificate', 'edit')
            "
          >
            {{ edit ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import ProgramService from "@/core/services/level/program/ProgramService";
import TemplatesService from "@/core/services/templates/TemplatesService";
import { required, email, requiredIf } from "vuelidate/lib/validators";

const program = new ProgramService();
const templateService = new TemplatesService();
export default {
  validations: {
    template: {
      file_name: { required },
      bg_image: {
        required: requiredIf(function () {
          return !this.edit;
        }),
      },
    },
    programDetail: { required },
  },

  data() {
    return {
      dialog: false,
      edit: false,
      programs: [],
      template: {
        type: "character_certificate",
        file_name: "",
        program_id: "",
        student_name_position: "",
        student_name_position_top: 0,
        student_name_position_left: 0,
        certificate_date_position: "",
        certificate_date_position_top: 0,
        certificate_date_position_left: 0,
        program_name_position: "",
        program_name_position_top: 0,
        program_name_position_left: 0,
        parent_name_position: "",
        parent_name_position_top: 0,
        parent_name_position_left: 0,
        completion_date_position: "",
        completion_date_position_top: 0,
        completion_date_position_left: 0,
        academic_year_position: "",
        academic_year_position_top: 0,
        academic_year_position_left: 0,
        address_position: "",
        address_position_top: 0,
        address_position_left: 0,
        registration_no_position: "",
        registration_no_position_top: 0,
        registration_no_position_left: 0,
        date_of_birth_position: "",
        date_of_birth_position_top: 0,
        date_of_birth_position_left: 0,
        gpa_position: "",
        gpa_position_top: 0,
        gpa_position_left: 0,
        division_position: "",
        division_position_top: "",
        division_position_left: "",
        transcript_issue_no_position: "",
        transcript_issue_no_position_top: 0,
        transcript_issue_no_position_left: 0,
        passed_year_position: "",
        passed_year_position_top: 0,
        passed_year_position_left: 0,
        grade_position: "",
        grade_position_top: 0,
        grade_position_left: 0,
        academic_year_start_position: "",
        academic_year_start_position_top: 0,
        academic_year_start_position_left: 0,
        academic_year_end_position: "",
        academic_year_end_position_top: 0,
        academic_year_end_position_left: 0,
        bg_image: null,
        is_active: 1,
      },
      templateDetail: null,
      previewImage: null,
      programDetail: null,
      character_certificate_fields: [],
      loading: false,
      settings: {
        orientation: "portrait",
        template_for: "Student",
      },
      size: {
        left_max: "",
        top_max: "",
        min: "",
      },
    };
  },

  computed: {
    titleType() {
      return this.template.type.replace("_", " ");
    },
  },

  methods: {
    openDialog(type = null) {
      if (type) {
        this.template.type = type;
      }
      this.dialog = true;
      this.getProgram();
      this.changeSizeValue();
    },

    closeDialog() {
      this.dialog = false;
      this.resetData();
    },

    resetData() {
      this.edit = false;
      this.template = {
        type: "character_certificate",
        file_name: "",
        program_id: "",
        student_name_position: "",
        student_name_position_top: 0,
        student_name_position_left: 0,
        certificate_date_position: "",
        certificate_date_position_top: 0,
        certificate_date_position_left: 0,
        program_name_position: "",
        program_name_position_top: 0,
        program_name_position_left: 0,
        parent_name_position: "",
        parent_name_position_top: 0,
        parent_name_position_left: 0,
        completion_date_position: "",
        completion_date_position_top: 0,
        completion_date_position_left: 0,
        academic_year_position: "",
        academic_year_position_top: 0,
        academic_year_position_left: 0,
        address_position: "",
        address_position_top: 0,
        address_position_left: 0,
        registration_no_position: "",
        registration_no_position_top: 0,
        registration_no_position_left: 0,
        date_of_birth_position: "",
        date_of_birth_position_top: 0,
        date_of_birth_position_left: 0,
        gpa_position: "",
        gpa_position_top: 0,
        gpa_position_left: 0,
        division_position: "",
        division_position_top: "",
        division_position_left: "",
        transcript_issue_no_position: "",
        transcript_issue_no_position_top: 0,
        transcript_issue_no_position_left: 0,
        passed_year_position: "",
        passed_year_position_top: 0,
        passed_year_position_left: 0,
        grade_position: "",
        grade_position_top: 0,
        grade_position_left: 0,
        academic_year_start_position: "",
        academic_year_start_position_top: 0,
        academic_year_start_position_left: 0,
        academic_year_end_position: "",
        academic_year_end_position_top: 0,
        academic_year_end_position_left: 0,
        bg_image: null,
        is_active: 1,
      };
      this.previewImage = null;
      this.programDetail = null;
      this.character_certificate_fields = [];
      this.loading = false;
      this.$v.$reset();
      this.templateDetail = null;
    },

    createCertificate() {
      this.edit = false;
      this.openDialog();
    },

    editResultCertificate(template) {
      this.edit = true;
      this.getTemplateDetail(template.id);
      this.openDialog();
    },

    editCertificate(templateId) {
      this.edit = true;
      this.getTemplateDetail(templateId);
      this.openDialog();
    },

    getProgram() {
      program
        .all()
        .then((response) => {
          this.programs = response.data;
        })
        .catch(() => {});
    },

    Preview_image() {
      this.previewImage = URL.createObjectURL(this.template.bg_image);
    },

    changeProgram() {
      this.character_certificate_fields = [];
      if (
        this.programDetail &&
        this.programDetail.character_certificate_fields
      ) {
        this.character_certificate_fields =
          this.programDetail.character_certificate_fields.split(",");
      } else {
        this.character_certificate_fields = [];
      }
      this.template.program_id = this.programDetail.id;
    },

    saveCertificate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.edit) {
          this.__create();
        } else {
          this.__update();
        }
      }
    },

    customizeTemplateData() {
      if (this.character_certificate_fields.includes("student_name")) {
        this.template.student_name_position =
          this.template.student_name_position_top +
          "," +
          this.template.student_name_position_left;
      }
      if (this.character_certificate_fields.includes("certificate_date")) {
        this.template.certificate_date_position =
          this.template.certificate_date_position_top +
          "," +
          this.template.certificate_date_position_left;
      }
      if (this.character_certificate_fields.includes("program_name")) {
        this.template.program_name_position =
          this.template.program_name_position_top +
          "," +
          this.template.program_name_position_left;
      }
      if (this.character_certificate_fields.includes("completion_date")) {
        this.template.completion_date_position =
          this.template.completion_date_position_top +
          "," +
          this.template.completion_date_position_left;
      }
      if (this.character_certificate_fields.includes("parent_name")) {
        this.template.parent_name_position =
          this.template.parent_name_position_top +
          "," +
          this.template.parent_name_position_left;
      }
      if (this.character_certificate_fields.includes("date_of_birth")) {
        this.template.date_of_birth_position =
          this.template.date_of_birth_position_top +
          "," +
          this.template.date_of_birth_position_left;
      }
      if (this.character_certificate_fields.includes("academic_year")) {
        this.template.academic_year_position =
          this.template.academic_year_position_top +
          "," +
          this.template.academic_year_position_left;
      }
      if (this.character_certificate_fields.includes("address")) {
        this.template.address_position =
          this.template.address_position_top +
          "," +
          this.template.address_position_left;
      }
      if (this.character_certificate_fields.includes("registration_no")) {
        this.template.registration_no_position =
          this.template.registration_no_position_top +
          "," +
          this.template.registration_no_position_left;
      }
      if (this.character_certificate_fields.includes("gpa")) {
        this.template.gpa_position =
          this.template.gpa_position_top +
          "," +
          this.template.gpa_position_left;
      }
      if (this.character_certificate_fields.includes("division")) {
        this.template.division_position =
          this.template.division_position_top +
          "," +
          this.template.division_position_left;
      }
      if (this.character_certificate_fields.includes("transcript_issue_no")) {
        this.template.transcript_issue_no_position =
          this.template.transcript_issue_no_position_top +
          "," +
          this.template.transcript_issue_no_position_left;
      }
      if (this.character_certificate_fields.includes("passed_year")) {
        this.template.passed_year_position =
          this.template.passed_year_position_top +
          "," +
          this.template.passed_year_position_left;
      }
      if (this.character_certificate_fields.includes("grade")) {
        this.template.grade_position =
          this.template.grade_position_top +
          "," +
          this.template.grade_position_left;
      }
      if (this.character_certificate_fields.includes("academic_year_start")) {
        this.template.academic_year_start_position =
          this.template.academic_year_start_position_top +
          "," +
          this.template.academic_year_start_position_left;
      }
      if (this.character_certificate_fields.includes("academic_year_end")) {
        this.template.academic_year_end_position =
          this.template.academic_year_end_position_top +
          "," +
          this.template.academic_year_end_position_left;
      }
      this.template.settings = this.settings;
      this.template.settings = JSON.stringify(this.template.settings);
    },

    convertToFormData() {
      let formData = new FormData();
      for (let key in this.template) {
        if (
          key === "bg_image" &&
          this.template[key] != null &&
          this.template[key] != undefined
        ) {
          formData.append("bg_image", this.template[key]);
        } else {
          if (this.template[key]) {
            formData.append(key, this.template[key]);
          }
        }
      }
      return formData;
    },

    __create() {
      this.loading = true;
      this.customizeTemplateData();
      let formData = this.convertToFormData();
      templateService
        .createByProgram(formData)
        .then((response) => {
          this.closeDialog();
          this.$emit("refresh");
          this.$snotify.success("Template Information added");
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    __update() {
      this.loading = true;
      this.customizeTemplateData();
      let formData = this.convertToFormData();
      templateService
        .update(this.template.id, formData)
        .then((response) => {
          this.$emit("refresh");
          this.closeDialog();
          this.$snotify.success("Template Information updated");
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    changeSizeValue() {
      if (this.settings.orientation == "portrait") {
        this.size.left_max = "793.92px";
        this.size.top_max = "1122.24px";
      }
      if (this.settings.orientation == "landscape") {
        this.size.left_max = "1122.24px";
        this.size.top_max = "793.92px";
      }
    },

    getTemplateDetail(templateId) {
      templateService
        .show(templateId)
        .then((response) => {
          this.templateDetail = response.data.templates;
          if (this.templateDetail) {
            this.template = this.templateDetail;
            this.programDetail = this.templateDetail.program;
            if (this.programDetail) {
              this.changeProgram();
            }
            this.previewImage = this.templateDetail.image_path["real"];
            this.settings.orientation = this.template.settings.orientation;
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.outer-border {
  padding: 20px;
  text-align: center;
  border: 5px solid #000000;
  margin-left: 21%;
}

.certification {
  font-size: 50px;
  font-weight: bold;
}

.certify {
  font-size: 25px;
}

.name {
  font-size: 30px;
  color: green;
}

.fs-30 {
  font-size: 30px;
  font-weight: bold;
}

.fs-20 {
  font-size: 20px;
  font-weight: bold;
}
.fs-15 {
  font-size: 15px;
  font-weight: bold;
}
.bgimg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
