<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Result certificate</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Result certificate
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('character-certificate', 'create')" @click="createResultCertificate()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add result certificate
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25">
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr>
                  <th class="px-3 wrap-column">File name</th>
                  <th class="px-3 wrap-column">Default template</th>
                  <th class="px-3 wrap-column">Program</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody v-if="templates.length > 0">
                <tr v-for="(template, index) of templates" :key="index">
                  <td class="px-3 wrap-column">
                    <a @click="editResultCertificate(template)"  class="mr-2 font-weight-medium">
                      {{template.file_name}}
                    </a>
                    <i class="fas fa-circle"
                       :class="template.is_active?'dot-active':'dot-inactive'"></i>
                  </td>
                  <td class="px-3 wrap-column"><span :class="template.is_default?'badge badge-success':'badge badge-danger'">{{template.is_default?'YES':'NO'}}</span>
                  </td>

                  <td class="px-3 wrap-column">
                    <span class="font-weight-medium" v-if="template.program_id">
                      {{template.program_title}}
                    </span>
                  </td>
                  <td  class="pr-3 text-center">
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i>
                          </span>
                        </slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('character-certificate', 'preview')">
                          <a href="#" class="navi-link"
                             @click="previewTemplate(template.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-eye"></i>
                                </span>
                            <span class="navi-text">  Preview</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('character-certificate', 'edit')">
                          <a href="#" class="navi-link"
                             @click="editResultCertificate(template)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                            <span class="navi-text">  Edit</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('character-certificate', 'delete')">
                          <a href="#" class="navi-link"
                             @click="deleteTemplate(template.id)">
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                            <span class="navi-text">  Delete</span>
                          </a>
                        </b-dropdown-text>

                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>

                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td class="text-center" colspan="10">Data not available</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="template" @refresh="getAllTemplates"></create-and-update>

      <preview-character-certificate ref="preview-template" @refresh="getAllTemplates"></preview-character-certificate>
    </div>
  </v-app>
</template>
<script>
import TemplatesService from "@/core/services/templates/TemplatesService";
import CreateAndUpdate from "@/view/pages/setting/certificate/CreateAndUpdate.vue";
import PreviewCharacterCertificate from "@/view/pages/setting/certificate/PreviewCharacterCertificate.vue";

const templatesService = new TemplatesService();
export default {
  components:{
    CreateAndUpdate,
    PreviewCharacterCertificate
  },
  data(){
    return{
      loading:false,
      templates:[],
      search: {
        type:'result_certificate'
      },
    }
  },
  mounted() {
    this.getAllTemplates();
  },
  methods:{
    createResultCertificate(){
      this.$refs['template'].openDialog('result_certificate');
    },

    editResultCertificate(template){
      this.$refs['template'].editResultCertificate(template);
    },

    previewTemplate(templateId){
      this.$refs['preview-template'].previewTemplate(templateId);
    },

    getAllTemplates(){
      this.loading=true;
      templatesService
          .paginate(this.search.type)
          .then(response => {
            this.templates = response.data.data;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading=false;
          });
    },

    deleteTemplate(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            templatesService
                .delete(id)
                .then(() => {
                  this.getAllTemplates()
                  this.$snotify.success("Template deleted");
                })
                .catch(() => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>
